














import { defineComponent, PropType, ref } from "@nuxtjs/composition-api";

import { CategoryTree } from "~/modules/GraphQL/types";
import { useUiHelpers } from "~/composables";
import type { ComponentTemplateRef } from "~/types/componentTemplateRef";
import HeaderNavigationItem from "./HeaderNavigationItem.vue";

export default defineComponent({
  name: "HeaderNavigation",
  components: {
    HeaderNavigationSubcategories: () =>
      import(
        "~/components/Header/Navigation/HeaderNavigationSubcategories.vue"
      ),
    HeaderNavigationItem,
  },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();

    const currentCategory = ref<CategoryTree | null>(null);
    const lvl0CatRefs = ref<ComponentTemplateRef[]>();
    const hasFocus = ref(false);
    let lvl0CatFocusIdx = 0;
    let focusedElement: HTMLElement | null = null;

    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
    };

    const hasChildren = (category: CategoryTree) =>
      Boolean(category?.children?.length);

    const setFocus = (event: MouseEvent & { target: HTMLElement }) => {
      focusedElement = event.target;
      lvl0CatFocusIdx = Number(event.target.dataset.index);
      hasFocus.value = true;
    };

    const focusRootElementAndHideSubcategories = () => {
      setCurrentCategory(null);
      if (focusedElement !== null) focusedElement.focus();
    };

    const navRight = () => {
      lvl0CatFocusIdx++;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx--;
      }
    };

    const navLeft = () => {
      lvl0CatFocusIdx--;
      if (lvl0CatRefs.value[lvl0CatFocusIdx]) {
        lvl0CatRefs.value[lvl0CatFocusIdx].$el.focus();
        focusedElement = lvl0CatRefs.value[lvl0CatFocusIdx].$el;
      } else {
        lvl0CatFocusIdx++;
      }
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;
      focusedElement = null;
      hasFocus.value = false;
    };

    return {
      getCatLink,
      setCurrentCategory,
      currentCategory,
      hasChildren,
      setFocus,
      focusRootElementAndHideSubcategories,
      lvl0CatRefs,
      navRight,
      navLeft,
      hasFocus,
      onMouseEnter,
    };
  },
});
