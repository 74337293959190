








import { SfIcon } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SaleIcon',
  components: { SfIcon },
});
