import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6d5053d3 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _0bd5f11a = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _71cf0119 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _c7073d36 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _3b2bb370 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _32ca01b4 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _1c57cd5b = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _e4793368 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _41d76ff6 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _72d270b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _fb8911d4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _5846aae4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _38d54212 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _35deead4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _87947e4c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6e916356 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _5c784462 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _6cee7dd1 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _429558b8 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1ff48446 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _6930a352 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _84f52cfa = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/default",
    component: _6d5053d3,
    name: "home___default"
  }, {
    path: "/german",
    component: _6d5053d3,
    name: "home___german"
  }, {
    path: "/default/cart",
    component: _0bd5f11a,
    name: "cart___default"
  }, {
    path: "/default/checkout",
    component: _71cf0119,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _c7073d36,
      name: "billing___default"
    }, {
      path: "payment",
      component: _3b2bb370,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _32ca01b4,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _1c57cd5b,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _e4793368,
      name: "user-account___default"
    }]
  }, {
    path: "/default/Cms",
    component: _41d76ff6,
    name: "Cms___default"
  }, {
    path: "/default/customer",
    component: _72d270b0,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _fb8911d4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _5846aae4,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _38d54212,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _35deead4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _87947e4c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _6e916356,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _5c784462,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "/default/reset-password",
      component: _6cee7dd1,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _429558b8,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _1ff48446,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/default/Faq",
    component: _6930a352,
    name: "Faq___default"
  }, {
    path: "/default/Home",
    component: _6d5053d3,
    name: "Home___default"
  }, {
    path: "/default/Page",
    component: _84f52cfa,
    name: "Page___default"
  }, {
    path: "/german/cart",
    component: _0bd5f11a,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _71cf0119,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _c7073d36,
      name: "billing___german"
    }, {
      path: "payment",
      component: _3b2bb370,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _32ca01b4,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _1c57cd5b,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _e4793368,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _41d76ff6,
    name: "Cms___german"
  }, {
    path: "/german/customer",
    component: _72d270b0,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _fb8911d4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _5846aae4,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _38d54212,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _35deead4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _87947e4c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _6e916356,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _5c784462,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _6cee7dd1,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _429558b8,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _1ff48446,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Faq",
    component: _6930a352,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _6d5053d3,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _84f52cfa,
    name: "Page___german"
  }, {
    path: "/default/:slug+",
    component: _84f52cfa,
    name: "page___default"
  }, {
    path: "/german/:slug+",
    component: _84f52cfa,
    name: "page___german"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
